import React, { useRef } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials'
import CTA from './CTA';
import FAQS from './FAQS';
import Footer from 'src/components/Footer';
import { MessengerChat } from "react-messenger-chat-plugin";
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dividerTop: {
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '75px',
      fill: theme.palette.background.dark,
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px',
      },
    },
  },
  dividerBottom: {
    backgroundColor: theme.palette.background.dark,
    transform: 'rotate(180deg)',
    '& svg': {
      width: 'calc(160% + 1.3px)',
      height: '75px',
      transform: 'rotateY(180deg)',
      fill: '#ffffff',
    },
    [theme.breakpoints.down('md')]: {
      '& svg': {
        width: 'calc(166% + 1.3px)',
        height: '62px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        width: 'calc(162% + 1.3px)',
        height: '35px',
      },
    },
  },
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });

  (window as any).scroll = executeScroll;

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      
    </Page>
  );
};


export default HomeView;
