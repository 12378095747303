import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import { version } from './config';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/develop',
    component: lazy(() => import('src/views/landing-page'))
  },
  {
    exact: true,
    path: '/thank-you',
    component: lazy(() => import('src/views/thank-you'))
  },
  {
    exact: true,
    path: '/feedback',
    component: lazy(() => import('src/views/feedback'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('src/views/privacy-policy'))
  },
  {
    exact: true,
    path: '/terms-of-use',
    component: lazy(() => import('src/views/terms-of-use'))
  },
  {
    exact: true,
    path: '/request-account-deletion',
    component: lazy(() => import('src/views/request-account-deletion'))
  },
  {
    exact: true,
    path: '/download',
    component: lazy(() => import('src/views/download'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/version',
    component: () => <>v{version}</>
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
